<template>
<div>
    <v-dialog v-model="openModal" persistent max-width="800">
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <p class="text-center font-weight-bold">Descripción luna</p>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                        <p class="mb-0">OJO DERECHO - OD</p>
                    </v-col>
                    <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                        <v-select item-text="texto" item-value="valor" dense :items="valoresRango(-16,16,0.25,'',2)" label="ESFERA" v-model="receta.odEsfera"></v-select>
                    </v-col>
                    <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                        <v-select item-text="texto" item-value="valor" dense :items="valoresRango(-6,0,0.25,'',2)" label="CILINDRO" v-model="receta.odCilindro"></v-select>
                    </v-col>
                    <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                        <v-select item-text="texto" item-value="valor" dense :items="valoresRango(0,180,1,'°',0)" label="EJE" v-model="receta.odEje"></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                        <p class="mb-0">OJO IZQUIERDO - OI</p>
                    </v-col>
                    <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                        <v-select item-text="texto" item-value="valor" dense :items="valoresRango(-16,16,0.25,'',2)" label="ESFERA" v-model="receta.oiEsfera"></v-select>
                    </v-col>
                    <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                        <v-select item-text="texto" item-value="valor" dense :items="valoresRango(-6,0,0.25,'',2)" label="CILINDRO" v-model="receta.oiCilindro"></v-select>
                    </v-col>
                    <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                        <v-select item-text="texto" item-value="valor" dense :items="valoresRango(0,180,1,'°',0)" label="EJE" v-model="receta.oiEje"></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                        <p class="mb-0">DISTANCIA INTERPUPILAR - DIP</p>
                    </v-col>
                    <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                        <v-select item-text="texto" item-value="valor" dense :items="valoresRango(50,80,1,' MM',0)" label="DIP" v-model="receta.dip"></v-select>
                    </v-col>
                    <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                        <v-text-field dense label="Nombre" v-model="receta.nombreMedida"></v-text-field>
                    </v-col>
                    <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                        <v-menu :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field :value="formatFecha" dense clearable label="Fecha" readonly @click:clear="receta.fechaMedida = ''" v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="receta.fechaMedida" locale="es-es"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-btn class="mr-2" color="error" outlined="" @click="openModal = false">
                            <v-icon left="">mdi-backspace</v-icon> Cerrar
                        </v-btn>
                        <v-btn color="success" @click="$emit('editarReceta',index,receta);openModal = false">
                            <v-icon left="">mdi-check-bold</v-icon> Aceptar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            openModal: false,
            index: '',
            receta: {
                odEsfera: 0,
                odCilindro: 0,
                odEje: 0,
                oiEsfera: 0,
                oiCilindro: 0,
                oiEje: 0,
                dip: 0,
                nombreMedida: '',
                fechaMedida: ''
            }
        }
    },
    props: {
        objModalRecetaEditar: {
            type: Object
        },
    },
    watch: {
        objModalRecetaEditar(val) {
            this.openModal = val.openModal
            this.index = val.index
            this.receta.odEsfera = val.receta.odEsfera;
            this.receta.odCilindro = val.receta.odCilindro;
            this.receta.odEje = val.receta.odEje;
            this.receta.oiEsfera = val.receta.oiEsfera;
            this.receta.oiCilindro = val.receta.oiCilindro;
            this.receta.oiEje = val.receta.oiEje;
            this.receta.dip = val.receta.dip;
            this.receta.nombreMedida = val.receta.nombreMedida;
            this.receta.fechaMedida = val.receta.fechaMedida;
        },
    },

    computed: {
        formatFecha() {
            return this.receta.fechaMedida ? this.$moment(this.receta.fechaMedida).format('DD/MM/YYYY') : '';
        },
    },

    methods: {
        valoresRango(inicio, final, contador, texto = '', decimal) {
            let valores = [];
            for (inicio; inicio <= final; inicio += contador) {
                valores.push({
                    texto: (inicio.toFixed(decimal)) + texto,
                    valor: inicio.toFixed(decimal)
                })
            }
            return valores;
        },
    },

}
</script>
