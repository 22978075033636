<template>
  <v-container fluid>
    <modal-receta
      @agregarReceta="agregarReceta"
      :objModalReceta="objModalReceta"
    ></modal-receta>
    <modal-receta-editar
      @editarReceta="editarReceta"
      :objModalRecetaEditar="objModalRecetaEditar"
    ></modal-receta-editar>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> NUEVO PEDIDO PERSONALIZADO </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'pedidoPersonalizadoPanel' }"
                    >Pedidos Personalizados</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'pedidoPersonalizadoCrearPanel' }"
                    >Crear</router-link
                  >
                </p>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row v-show="!mostrarContenido">
              <v-col cols="12 text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-row v-show="mostrarContenido">
              <v-col cols="12">
                <v-alert type="error" v-model="alertError" dismissible ref="mensajeError">
                  <ul v-for="(errors, index) in listErrors" :key="index">
                    <li v-text="errors[0]"></li>
                  </ul>
                </v-alert>
              </v-col>

              <v-col xl="5" lg="5" md="5" sm="12" cols="12">
                <v-card
                  class=""
                  elevation=""
                  max-width=""
                  style="border: 1px solid silver"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          @change="buscarProductos()"
                          clearable
                          dense
                          item-text="nombre"
                          item-value="idmodelo_producto"
                          :items="arrayClase"
                          v-model="buscarProducto.idclase"
                          label="Clase"
                          outlined
                        ></v-select>
                        <v-select
                          @change="buscarProductos()"
                          clearable
                          dense
                          item-text="nombre"
                          item-value="idgenero"
                          :items="arrayGenero"
                          v-model="buscarProducto.idgenero"
                          label="Genero"
                          outlined
                        ></v-select>
                        <v-text-field
                          @keyup.prevent.enter="buscarProductos()"
                          @click:clear="
                            buscarProducto.nombre = '';
                            buscarProductos();
                          "
                          @keyup.prevent.delete="buscarProductos()"
                          v-model="buscarProducto.nombre"
                          clearable
                          autocomplete="off"
                          outlined
                          dense
                          label="Buscar producto..."
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-show="productosEncontrados.length > 0">
                        <v-simple-table class="tabla-buscar-producto">
                          <thead>
                            <tr>
                              <th colspan="4">LISTA DE PRODUCTOS EXISTENTES</th>
                            </tr>
                            <tr>
                              <th>AGREGAR</th>
                              <th>IMAGEN</th>
                              <th>NOMBRE</th>
                              <th>CÓDIGO</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(p, index) in productosEncontrados" :key="index">
                              <td class="text-center">
                                <v-btn
                                  @click="agregarProducto(p)"
                                  color="primary"
                                  small
                                  icon=""
                                >
                                  <v-icon>mdi-plus-thick</v-icon>
                                </v-btn>
                              </td>
                              <td class="text-center">
                                <img
                                  v-if="p.imagenes != null"
                                  :src="assetAWS('monturas/' + p.imagenes[0].nombre)"
                                  width="30px"
                                  height="25px"
                                />
                              </td>
                              <td>
                                <p v-text="p.nombre"></p>
                              </td>
                              <td>
                                <p v-text="p.codigo"></p>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12 text-center" v-show="loaderTablaBuscarProductos">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          :size="50"
                          :width="7"
                        ></v-progress-circular>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card
                  v-show="productoAgredado.nombre != ''"
                  class="mt-1"
                  elevation=""
                  max-width=""
                  style="border: 1px solid silver"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <h3 class="my-0" v-text="productoAgredado.nombre"></h3>
                        <p class="my-0" v-if="productoAgredado.tipoMontura.length > 0">
                          <span
                            v-for="(tm, index) in productoAgredado.tipoMontura"
                            :key="index"
                            v-html="tm.nombre + '</br>'"
                          ></span>
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="productoAgredado.tipoMedida"
                          dense
                          item-text="nombre"
                          item-value="tipo_medida"
                          :items="arrayTipoMedida"
                          label="Tipo de medida"
                          outlined
                          return-object
                        ></v-select>
                        <v-btn
                          @click="
                            objModalReceta = {
                              openModal: true,
                              receta: productoAgredado.receta,
                            }
                          "
                          class="mb-2"
                          color="primary"
                          small
                        >
                          <v-icon small>mdi-prescription</v-icon> Subir receta
                        </v-btn>
                        <v-text-field
                          v-model="productoAgredado.precioMontura"
                          type="number"
                          min="0"
                          outlined
                          dense
                          label="Precio montura"
                        ></v-text-field>
                        <v-text-field
                          v-model="productoAgredado.nombreLuna"
                          outlined
                          dense
                          label="Nombre luna"
                        ></v-text-field>
                        <v-text-field
                          v-model="productoAgredado.precioLuna"
                          type="number"
                          min="0"
                          outlined
                          dense
                          label="Precio luna"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-btn
                          class="mb-2"
                          color="success"
                          @click="agregarProductoDetalle"
                        >
                          <v-icon>mdi-plus-thick</v-icon> Agregar "<span
                            v-text="productoAgredado.nombre"
                          ></span
                          >"
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col xl="7" lg="7" md="7" sm="12" cols="12">
                <v-card
                  class=""
                  elevation=""
                  max-width=""
                  style="border: 1px solid silver"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-simple-table class="tabla-productos-agregados">
                          <thead>
                            <tr>
                              <th>N°</th>
                              <th>IMAGEN</th>
                              <th>CÓDIGO</th>
                              <th>NOMBRE MONTURA</th>
                              <th>TIPO MONTURA</th>
                              <th>PRECIO MONTURA</th>
                              <th>NOMBRE LUNA</th>
                              <th>PRECIO LUNA</th>
                              <th>IMPORTE TOTAL</th>
                              <th>TIPO MEDIDA</th>
                              <th>RECETA</th>
                              <th>ELIMINAR</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(p, index) in productoDetalle" :key="index">
                              <td>
                                <p v-text="index + 1"></p>
                              </td>
                              <td class="text-center">
                                <img
                                  v-if="p.imagenes.length > 0"
                                  :src="assetAWS('monturas/' + p.imagenes[0].nombre)"
                                  width="30px"
                                  height="25px"
                                />
                              </td>
                              <td>
                                <p v-text="p.codigo"></p>
                              </td>
                              <td>
                                <p v-text="p.nombre"></p>
                              </td>
                              <td>
                                <p class="my-0" v-if="p.tipoMontura.length > 0">
                                  <span
                                    v-for="(tm, index) in p.tipoMontura"
                                    :key="index"
                                    v-html="tm.nombre + '</br>'"
                                  ></span>
                                </p>
                              </td>
                              <td>
                                <p v-text="p.precioMontura"></p>
                              </td>
                              <td>
                                <p v-text="p.nombreLuna"></p>
                              </td>
                              <td>
                                <p v-text="p.precioLuna"></p>
                              </td>
                              <td>
                                <p v-text="p.importeTotal"></p>
                              </td>
                              <td>
                                <p
                                  v-if="p.tipoMedida != null"
                                  v-text="p.tipoMedida.nombre"
                                ></p>
                              </td>
                              <td class="text-center">
                                <v-btn
                                  v-if="p.tipoMedida.nombre !== 'sin medida'"
                                  @click="
                                    objModalRecetaEditar = {
                                      openModal: true,
                                      index: index,
                                      receta: p.receta,
                                    }
                                  "
                                  color="primary"
                                  small
                                >
                                  Ver <v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </td>
                              <td class="text-center">
                                <v-btn
                                  @click="eliminarProductoDetalle(index)"
                                  color="error"
                                  small
                                  icon=""
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col xl="12" lg="12" md="12" sm="12" cols="12">
                <v-card
                  class=""
                  elevation=""
                  max-width=""
                  style="border: 1px solid silver"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          @input="calcularTotalPagar"
                          v-model="precioEnvio"
                          type="number"
                          min="0"
                          outlined
                          dense
                          label="Precio de envio"
                        ></v-text-field>
                        <v-text-field
                          @input="calcularTotalPagar"
                          v-model="descuento"
                          type="number"
                          min="0"
                          outlined
                          dense
                          label="Descuento"
                        ></v-text-field>
                        <v-text-field
                          readonly
                          v-model="totalPagar"
                          type="number"
                          min="0"
                          outlined
                          dense
                          label="Total a pagar"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" class="text-center">
                <v-btn
                  class="mr-2"
                  @click="guardar"
                  :loading="processForm"
                  color="success"
                  large=""
                >
                  <v-icon left="" large="">mdi-content-save</v-icon> GUARDAR
                </v-btn>
                <v-btn
                  :to="{ name: 'pedidoPersonalizadoPanel' }"
                  type="button"
                  :loading="processForm"
                  color="error"
                  large=""
                >
                  <v-icon left="" large="">mdi-close</v-icon> CANCELAR
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import modalReceta from "./Receta";
import modalRecetaEditar from "./RecetaEditar";
export default {
  components: {
    modalReceta,
    modalRecetaEditar,
  },
  data() {
    return {
      buscarProducto: {
        idclase: "",
        idgenero: "",
        nombre: "",
      },
      productoAgredado: {
        idproducto: "",
        imagenes: [],
        codigo: "",
        nombre: "",
        tipoMontura: [],
        precioMontura: 0,
        nombreLuna: "",
        precioLuna: 0,
        importeTotal: 0,
        tipoMedida: "",
        receta: {
          odEsfera: 0,
          odCilindro: 0,
          odEje: 0,
          oiEsfera: 0,
          oiCilindro: 0,
          oiEje: 0,
          dip: 0,
          nombreMedida: "",
          fechaMedida: "",
        },
      },
      productosEncontrados: [],
      productoDetalle: [],
      arrayClase: [],
      arrayGenero: [],
      arrayTipoMedida: [],
      listErrors: [],
      precioEnvio: 0,
      descuento: 0,
      totalPagar: 0,
      processForm: false,
      alertError: false,
      mostrarContenido: false,
      loaderTablaBuscarProductos: false,
      objModalReceta: null,
      objModalRecetaEditar: null,
    };
  },

  methods: {
    buscarProductos() {
      this.productosEncontrados = [];
      this.loaderTablaBuscarProductos = true;

      this.axios({
        method: "POST",
        url: "api/panel/pedidos-personalizados/buscarProducto",
        data: this.buscarProducto,
      })
        .then((response) => {
          if (response.status === 200) {
            this.productosEncontrados = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loaderTablaBuscarProductos = false;
        });
    },

    agregarProducto(producto) {
      this.productoAgredado.idproducto = producto.idproducto;
      this.productoAgredado.imagenes = producto.imagenes;
      this.productoAgredado.codigo = producto.codigo;
      this.productoAgredado.nombre = producto.nombre;
      this.productoAgredado.tipoMontura = producto.clases;
    },

    agregarReceta(receta) {
      this.productoAgredado.receta.odEsfera = receta.odEsfera;
      this.productoAgredado.receta.odCilindro = receta.odCilindro;
      this.productoAgredado.receta.odEje = receta.odEje;
      this.productoAgredado.receta.oiEsfera = receta.oiEsfera;
      this.productoAgredado.receta.oiCilindro = receta.oiCilindro;
      this.productoAgredado.receta.oiEje = receta.oiEje;
      this.productoAgredado.receta.dip = receta.dip;
      this.productoAgredado.receta.nombreMedida = receta.nombreMedida;
      this.productoAgredado.receta.fechaMedida = receta.fechaMedida;
    },

    editarReceta(index, receta) {
      this.productoDetalle[index].receta.odEsfera = receta.odEsfera;
      this.productoDetalle[index].receta.odCilindro = receta.odCilindro;
      this.productoDetalle[index].receta.odEje = receta.odEje;
      this.productoDetalle[index].receta.oiEsfera = receta.oiEsfera;
      this.productoDetalle[index].receta.oiCilindro = receta.oiCilindro;
      this.productoDetalle[index].receta.oiEje = receta.oiEje;
      this.productoDetalle[index].receta.dip = receta.dip;
      this.productoDetalle[index].receta.nombreMedida = receta.nombreMedida;
      this.productoDetalle[index].receta.fechaMedida = receta.fechaMedida;
    },

    agregarProductoDetalle() {
      this.productoDetalle.push({
        idproducto: this.productoAgredado.idproducto,
        idclase: this.buscarProducto.idclase,
        imagenes: this.productoAgredado.imagenes,
        codigo: this.productoAgredado.codigo,
        nombre: this.productoAgredado.nombre,
        tipoMontura: this.productoAgredado.tipoMontura,
        precioMontura: this.productoAgredado.precioMontura,
        nombreLuna: this.productoAgredado.nombreLuna,
        precioLuna: this.productoAgredado.precioLuna,
        importeTotal:
          Number(this.productoAgredado.precioMontura) +
          Number(this.productoAgredado.precioLuna),
        tipoMedida: this.productoAgredado.tipoMedida,
        receta: {
          odEsfera: this.productoAgredado.receta.odEsfera,
          odCilindro: this.productoAgredado.receta.odCilindro,
          odEje: this.productoAgredado.receta.odEje,
          oiEsfera: this.productoAgredado.receta.oiEsfera,
          oiCilindro: this.productoAgredado.receta.oiCilindro,
          oiEje: this.productoAgredado.receta.oiEje,
          dip: this.productoAgredado.receta.dip,
          nombreMedida: this.productoAgredado.receta.nombreMedida,
          fechaMedida: this.productoAgredado.receta.fechaMedida,
        },
      });

      this.limpiarProducto();

      this.calcularTotalPagar();
    },

    eliminarProductoDetalle(index) {
      this.$delete(this.productoDetalle, index);
      this.calcularTotalPagar();
    },

    limpiarProducto() {
      this.productoAgredado.idproducto = "";
      this.productoAgredado.imagenes = [];
      this.productoAgredado.codigo = "";
      this.productoAgredado.nombre = "";
      this.productoAgredado.tipoMontura = [];
      this.productoAgredado.precioMontura = 0;
      this.productoAgredado.nombreLuna = "";
      this.productoAgredado.precioLuna = 0;
      this.productoAgredado.importeTotal = 0;
      this.productoAgredado.tipoMedida = "";

      this.productoAgredado.receta.odEsfera = 0;
      this.productoAgredado.receta.odCilindro = 0;
      this.productoAgredado.receta.odEje = 0;
      this.productoAgredado.receta.oiEsfera = 0;
      this.productoAgredado.receta.oiCilindro = 0;
      this.productoAgredado.receta.oiEje = 0;
      this.productoAgredado.receta.dip = 0;
      this.productoAgredado.receta.nombreMedida = "";
      this.productoAgredado.receta.fechaMedida = "";
    },

    calcularTotalPagar() {
      let total = 0;
      this.productoDetalle.forEach((producto) => {
        total += Number(producto.importeTotal);
      });

      this.totalPagar = total + (this.precioEnvio - this.descuento);
    },

    getDatos() {
      this.axios({
        method: "GET",
        url: "api/panel/pedidos-personalizados/create",
      })
        .then((response) => {
          if (response.status === 200) {
            this.arrayClase = response.data.data.clases;
            this.arrayGenero = response.data.data.generos;
            this.arrayTipoMedida = response.data.data.tipoMedida;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.mostrarContenido = true;
        });
    },

    guardar() {
      this.alertError = false;
      this.processForm = true;

      this.axios({
        method: "POST",
        url: "api/panel/pedidos-personalizados",
        data: {
          productoDetalle: JSON.stringify(this.productoDetalle),
          precioEnvio: this.precioEnvio,
          descuento: this.descuento,
          totalPagar: this.totalPagar,
        },
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router.push({
              name: "pedidoPersonalizadoPanel",
            });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al guardar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },

  created() {
    if (!this.validarPermiso("pedidosPersonalizados.crear")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    window.scrollTo(0, 0);
    this.getDatos();
  },
};
</script>

<style scoped>
.tabla-buscar-producto {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-buscar-producto thead tr th {
  white-space: nowrap;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-buscar-producto tbody tr td {
  white-space: nowrap;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-productos-agregados {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-productos-agregados thead tr th {
  white-space: nowrap;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-productos-agregados tbody tr td {
  white-space: nowrap;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
